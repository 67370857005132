import React from 'react'

function CUserItem({user}) {
    return (
        <div className="c-user-item">

            <img src={require("../../assets/images/avatar.jpg").default} />

            <div className="text-container">
                <h5>{user?.name}</h5>

                <h5><b>{user?.course}</b></h5>
                <h5><b>Semester: {user?.semester}</b></h5>

                <span>{user?.diploma}</span>
            </div>
        </div>
    )
}

export default CUserItem
