import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { store } from "./store";
import { Provider } from 'react-redux'

import './assets/scss/index.scss';
import CHeader from './components/CHeader/CHeader';
import LoginPage from './pages/login/Login';
import CFooter from './components/CFooter/CFooter';
import { auth } from './firebase';
import DashboardPage from './pages/dashboard/Dashbaord';


function App() {

  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        setUser(userAuth);
      } else {
        setUser(false);
      }
    });
  }, []);

  if (user === null) { return null }


  return (

    <div className="App">

      <Provider store={store}>
        <Router>

          <CHeader />
          <Switch>
          { auth.currentUser ? 
          
            <Route exact path="/" component={DashboardPage} />
            :
            <Route exact path="/" component={LoginPage} />
          }
          </Switch>

         { !auth.currentUser &&  <CFooter /> }

        </Router>
      </Provider>

    </div>

  );

}

export default App;
