import React from 'react'

/**
 * This is a shared footer for all the screens
 * @returns null
 */

function CFooter() {
    return (
        <div className="c-footer">

            <span>
                Copyright
           </span>

        </div>

    )
}

export default CFooter
