import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { Form, Input, Button } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { auth, firestore } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../store/Actions/UsersActions";
import { Select } from 'antd';
import CUserItem from "../../components/CUserItem/CUserItem";
import { Checkbox } from 'antd';
import CFilterGroup from "../../components/CFilterGroup/CFilterGroup";
import { filtersData } from "../../utils/constants";
import AddUser from "./AddUser/AddUser";
import { convertToArray } from "../../utils/helpers";

const { Option } = Select;

export default function DashboardPage() {
  const history = useHistory();
  const [filters, setfilters] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {

    // if user logged in already move to account page
    if (!auth.currentUser) {
      history.push('/');
    }
    getUsers();

  }, [auth.currentUser]);

  const getUsers = () => {
    firestore.collection('users').get().then(res => {
      setUsers(convertToArray(res.docs))
    }).catch(err => console.log(err));
  }

  const handleFilterChange = ({ fieldName, value, checkboxOptionName = '', isCheckBox = false, isAllowedMultipleOptions = false }) => {

    if (isCheckBox) {
      if (value)
        if(isAllowedMultipleOptions)
        setfilters(prev => ({ ...prev, [fieldName]: { isAllowedMultipleOptions, value:  [...(prev[fieldName]?.value ? prev[fieldName].value : []) , checkboxOptionName] }}))
        else
        setfilters(prev => ({ ...prev, [fieldName]: [...(prev[fieldName] ? prev[fieldName] : []), checkboxOptionName] }))
      else
        if(isAllowedMultipleOptions)
        setfilters(prev => ({ ...prev, [fieldName]: { isAllowedMultipleOptions, value: [...prev[fieldName]?.value.filter(it => it !== checkboxOptionName)] }}))
        else 
        setfilters(prev => ({ ...prev, [fieldName]: [...prev[fieldName]?.filter(it => it !== checkboxOptionName)] }))

      return;
    }

    setfilters(prev => ({ ...prev, [fieldName]: value }));

  }

  return (
    <div className="dashboard-container">

      <div className="first-container">

        {Object.values(filtersData).map(filter => {
          return <CFilterGroup fieldName={filter.fieldName} isAllowedMultipleOptions={filter?.isAllowedMultipleOptions} handleFilterChange={handleFilterChange} title={filter.title} isSlider={filter?.isSlider} options={filter.options} />
        })}


        {/* <Button className="c-button large mt-4" >
          Apply Filter
        </Button> */}

      </div>

      <div className="second-container">
        <h3><b>EBS Symposium</b></h3>
        <h4>CV Platform</h4>

        <div className="d-flex justify-content-between align-items-center p-2 mt-2">

          <span><b>568 participants have uploaded their CV</b></span>
          <span><b>Last Updated:</b> Thursday, 8.9.22</span>
{/*
          <div className="d-flex align-items-center">
            <Button onClick={() => setIsModalVisible(true)} className="c-button large mt-4" >
              Add User
            </Button>
            {/* <span style={{ whiteSpace: 'nowrap', marginRight: 10 }}>Sort by </span>
            <Select defaultValue="relevance" style={{ width: 120 }} onChange={() => { }}>
              <Option value="relevance">Relevance</Option>
              <Option value="ascending">Ascending</Option>
              <Option value="descending">Descending</Option>
            </Select>
          </div>*/} 

        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap p-2 mt-2">
        <span><b>Important notice:</b></span><br></br>
         <span><i>The CVs are sorted by the date of uploading, the newest are at the Top.</i></span>
        </div>

    {/* 
        <div className="d-flex flex-row align-items-center pb-4 pt-0 pl-0 g-2">
          <div onClick={() => handleFilterChange({
            fieldName: 'physical_attendance',
            value: ''
          })} className={`c-filter-button m-0 ${!filters?.physical_attendance && 'active'}`}>
            All participants
          </div>
          <div onClick={() => handleFilterChange({
            fieldName: 'physical_attendance',
            value: 'Yes'
          })} className={`c-filter-button ${filters?.physical_attendance?.includes('Yes') && 'active'}`}>
            Physical Attendance
          </div>
          <div onClick={() => handleFilterChange({
            fieldName: 'physical_attendance',
            value: 'No'
          })} className={`c-filter-button ${filters?.physical_attendance?.includes('No') && 'active'}`}>
            Online Attendance
          </div>
        </div>
        */}
        

        <div className="users-container">
          {users.filter(user => {

            let isUserEligiable = true;

            Object.entries(filters).map(([key, values]) => {
              
              let userValue = user[key];
               
              // For multiple options
              if(values?.value && values?.value?.length){
               
                userValue = userValue?.split(",").map(it=>it.trim());
                isUserEligiable = userValue.some(option=> values.value?.includes(option));

              }
              else if (values?.length && !values?.includes(userValue.trim())) {
                isUserEligiable = false;
              }

            })

            return isUserEligiable;

          }).map(it => {
            return <a href={it?.cv_link} target="_blank" ><CUserItem user={it} /></a>;
          })}
        </div>

        <AddUser CB={()=> {getUsers(); setfilters({});}} setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />


      </div>

    </div>
  );
}
