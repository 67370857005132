import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import { Form, Input, Select } from 'antd';
import { filtersData } from '../../../utils/constants';
import { firestore } from '../../../firebase';
const { Option } = Select;

const AddUser = ({
    isModalVisible,
    setIsModalVisible,
    CB
}) => {
    const [loading, setLoading] = useState();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        setLoading(true);
        
        let payload  = {...values};
        Object.entries(values).map(([key,value])=>{
            if(Array.isArray(value)){
                let stringValue = '';
                value.map((it,index) => {
                      stringValue += it +(value.length !== (index +1 ) ? ', ':'')
                });
                payload[key] = stringValue
            }
        });

        firestore.collection('users').add(payload).then(res => {
            setLoading(false);
            message.success("User added!");
            CB && CB();
            setIsModalVisible(false);
        }).catch(err => console.log(err));
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const getInitialValues =()=>{
        let initialValues = {};
        Object.values(filtersData).map(it => {
            initialValues[it.fieldName] = it.options[0].value;
        })
        return initialValues
    }

    return (
        <>
            <Modal bodyStyle={{ paddingBottom: 0 }} footer={() => null} title="Add User" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                    initialValues={getInitialValues()}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item
                        label="CV link"
                        name="cv_link"
                        rules={[{ required: true, message: 'Please input your CV link!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="University"
                        name="university"
                        rules={[{ required: true, message: 'Please input your university!' }]}
                    >
                        <Input />
                    </Form.Item>

                    {Object.values(filtersData).map(it => {
                        return <Form.Item name={it.fieldName} label={it.title}>
                            <Select mode={it?.isAllowedMultipleOptions && 'multiple'} defaultValue={it.options[0].value}>
                                {it.options.map(option => {
                                    return <Option value={option.value}>{option.label}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    })}

                    <Form.Item className="mt-4 " wrapperCol={{ offset: 8, span: 16 }}>
                        <Button loading={loading} className="c-button large" type="primary" htmlType="submit">
                            Add User
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddUser;