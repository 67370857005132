import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCfIXqlj673sE-gLzUXT3zI939oK1tyVQw",
    authDomain: "ebs-symposium-cv-platform.firebaseapp.com",
    projectId: "ebs-symposium-cv-platform",
    storageBucket: "ebs-symposium-cv-platform.appspot.com",
    messagingSenderId: "518746518123",
    appId: "1:518746518123:web:f97ade515f462eddeef72e",
    measurementId: "G-V9XETCL3FY"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const database = firebase.database();
