import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { auth } from '../../firebase';
import { Dropdown, Menu, Skeleton } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getUserData, logout } from '../../store/Actions/UsersActions';

/**
 * This is a shared header for all the screens
 * @returns {JSX}
 */
function CHeader() {

    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        // load user details if logged in
        if (auth.currentUser) {
           
        }
    }, []);

    const menu = (
        <Menu>
            <Menu.Item onClick={() => dispatch(logout(()=> history.push('/')))}>
                <LogoutOutlined /> Sign Out
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="c-header">

            <div className="first-header">

                <Link to="/">
                    <img src={require('../../assets/images/logo.png').default} className="logo" />
                </Link>

                <div className="actions-bar">
                    <img src={require('../../assets/images/headerimage.PNG').default} className="logo" />

                    {auth.currentUser ?

                        <Dropdown overlay={menu} placement="bottomLeft" arrow>
                            <UserOutlined className="user-icon" />
                        </Dropdown>
                        :

                        null
                    }

                </div>

            </div>

        </div>

    )
}

export default CHeader
