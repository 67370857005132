export const filtersData = {
    work_experience: {
      options: [
        { value: '0-1 Years Experience', label: '0-1 Years Experience' },
        { value: '1-2 Years Experience', label: '1-2 Years Experience' }, 
        { value: '3-5 Years Experience', label: '3-5 Years Expereince' }, 
        { value: '5+ Years Experience', label: '5+ Years Experience' }, 
        { value: '10+ Years Experience', label: '10+ Years Experience' }, 
      ],
      title: 'Work Experience',
      fieldName:'work_experience',
    },
    sector: {
      options: [
        { value: 'No Prior Working Experience', label: 'No Prior Working Experience' },
        { value: 'Private Equity/Venture Capital', label: 'Private Equity/Venture Capital' }, 
        { value: 'Investment Banking', label: 'Investment Banking' }, 
        { value: 'Human Resources', label: 'Human Resources' },
        { value: 'Consulting', label: 'Consulting' },
        { value: 'Marketing/Branding/Sales', label: 'Marketing/Branding/Sales' },
        { value: 'Other', label: 'Other' },
        { value: 'Controlling', label: 'Controlling' },
        { value: 'Engineering', label: 'Engineering' },
        { value: 'IT/Software', label: 'IT/Software' },
        { value: 'Sales', label: 'Sales' },
        { value: 'Audit', label: 'Audit' },
        { value: 'Accounting', label: 'Accounting' },
      ],
      title: 'Sector',
      fieldName:'sector',
      isAllowedMultipleOptions : true
    },
    experience_kind: {
        title:"Experience Kind",
        fieldName:"experience_kind",
      options: [
        { value: 'Employee', label: 'Employee' }, 
        { value: 'Intern', label: 'Intern' }, 
        { value: 'Working Student', label: 'Working Student' }, 
        { value: '', label: 'No Prior Working Experience' }, 
      ],
      isAllowedMultipleOptions: true,

    },
    diploma: {
        title:"Diploma",
        fieldName:"diploma",
      options: [
        { value: 'Bachelor', label: 'Bachelor' }, 
        { value: 'Master', label: 'Master' }, 
        { value: 'Staatsexamen', label: 'Staatsexamen' }, 
        { value: 'Doctoral Candidate', label: 'Doctoral Candidate' }, 
      ],
      isAllowedMultipleOptions: true,
    },
    course: {
        title:"Course",
        fieldName:"course",
        isAllowedMultipleOptions: true,
      options: [
        { value: 'Business Studies/BWL', label: 'Business Studies/BWL' },
        { value: 'Software Engineering', label: 'Software Engineering' },
        { value: 'Computer Science', label: 'Computer Science' },
        { value: 'Business and Technology', label: 'Business and Technology' },
        { value: 'Corporate Mathematics', label: 'Corporate Mathematics' },
        { value: 'Corporate Finance', label: 'Corporate Finance' },
        { value: 'Environmental Engineering', label: 'Environmental Engineering' },
        { value: 'International Relations', label: 'International Relations' },
        { value: 'Economics/VWL', label: 'Economics/VWL' },
        { value: 'Management', label: 'Management' },
        { value: 'Law', label: 'Law' },
        { value: 'Entrepreneurship', label: 'Entrepreneurship'}
      ], 
    },
    finished_studies: {
        title:"Finished Studies",
        fieldName:"finished_studies",
      options: [
        { value: '1', label: 'Yes' },
        { value: '0', label: 'No' },
      ], 
    },
    semester: {
        title:"Semester",
        fieldName:"semester",
      options: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8+' },
      ], 
    },
  }