import React from 'react'
import { Checkbox } from 'antd';
import { Slider } from 'antd';

function CFilterGroup({ title, fieldName, handleFilterChange, isAllowedMultipleOptions, options = [], isSlider = false, max = 100, min = 1 }) {

    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
        handleFilterChange({ fieldName: fieldName,value: e.target.checked,  checkboxOptionName: e.target.value , isCheckBox: true, isAllowedMultipleOptions})
    }

   

    function onAfterChange(value) {
        console.log('onAfterChange: ', value);
        handleFilterChange({fieldName: fieldName,value:value})
    }


    return (
        <div className="c-filter-group">
            <h6><b>{title}</b></h6>
            <div className="options-container">

                {isSlider ? <Slider
                    range
                    max={max}
                    min={min}
                    step={10}
                    defaultValue={[20, 50]}
                    onAfterChange={onAfterChange}
                />
                    :
                    <>
                        {options.map(option => {
                            return <Checkbox name={fieldName} value={option.value} onChange={onChange}>{option.label}</Checkbox>
                        })}
                    </>
                }
            </div>
        </div>
    )
}

export default CFilterGroup
